const GA_COOCKIE_KEY = "gdpr-google-analytics"
const FB_PIXEL_COOCKIE_KEY = "gdpr-facebook-pixel"
const ADULT_KEY = "is_adult"
const REDIRECT_URL = "redirect_url"

module.exports = {
  GA_COOCKIE_KEY,
  FB_PIXEL_COOCKIE_KEY,
  ADULT_KEY,
  REDIRECT_URL,
}
