import { navigate } from "gatsby"
import Cookies from "universal-cookie"

import { ADULT_KEY, REDIRECT_URL } from "./src/constants"
import "./src/global.scss"

export { wrapRootElement, wrapPageElement } from "./gatsby-ssr"

const PUBLIC_ROUTES = [
  "/en/cookie",
  "/sr/politika-privatnosti-i-kolacica",
  "/sr/opsti-uslovi-i-odredbe",
  "/en/terms",
]

const setRedirectUrl = pathname => {
  if (pathname === "/") return
  const cookies = new Cookies()
  cookies.set(REDIRECT_URL, pathname)
}

export function onRouteUpdate({ location }) {
  const cookies = new Cookies()
  const isAdult =
    cookies.get(ADULT_KEY) === "true" ||
    PUBLIC_ROUTES.includes(location.pathname)

  if (!isAdult) {
    setRedirectUrl(location.pathname)
    navigate("/")
  }
}
