// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-age-verify-index-jsx": () => import("./../../../src/pages/age-verify/index.jsx" /* webpackChunkName: "component---src-pages-age-verify-index-jsx" */),
  "component---src-pages-apricot-index-jsx": () => import("./../../../src/pages/apricot/index.jsx" /* webpackChunkName: "component---src-pages-apricot-index-jsx" */),
  "component---src-pages-aron-index-jsx": () => import("./../../../src/pages/aron/index.jsx" /* webpackChunkName: "component---src-pages-aron-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-cookie-index-jsx": () => import("./../../../src/pages/cookie/index.jsx" /* webpackChunkName: "component---src-pages-cookie-index-jsx" */),
  "component---src-pages-get-to-know-us-index-jsx": () => import("./../../../src/pages/get-to-know-us/index.jsx" /* webpackChunkName: "component---src-pages-get-to-know-us-index-jsx" */),
  "component---src-pages-gorda-index-jsx": () => import("./../../../src/pages/gorda/index.jsx" /* webpackChunkName: "component---src-pages-gorda-index-jsx" */),
  "component---src-pages-history-index-jsx": () => import("./../../../src/pages/history/index.jsx" /* webpackChunkName: "component---src-pages-history-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-how-to-drink-index-jsx": () => import("./../../../src/pages/how-to-drink/index.jsx" /* webpackChunkName: "component---src-pages-how-to-drink-index-jsx" */),
  "component---src-pages-how-was-made-index-jsx": () => import("./../../../src/pages/how-was-made/index.jsx" /* webpackChunkName: "component---src-pages-how-was-made-index-jsx" */),
  "component---src-pages-interesting-facts-index-jsx": () => import("./../../../src/pages/interesting-facts/index.jsx" /* webpackChunkName: "component---src-pages-interesting-facts-index-jsx" */),
  "component---src-pages-meet-our-brandies-index-jsx": () => import("./../../../src/pages/meet-our-brandies/index.jsx" /* webpackChunkName: "component---src-pages-meet-our-brandies-index-jsx" */),
  "component---src-pages-meet-us-index-jsx": () => import("./../../../src/pages/meet-us/index.jsx" /* webpackChunkName: "component---src-pages-meet-us-index-jsx" */),
  "component---src-pages-plum-index-jsx": () => import("./../../../src/pages/plum/index.jsx" /* webpackChunkName: "component---src-pages-plum-index-jsx" */),
  "component---src-pages-production-index-jsx": () => import("./../../../src/pages/production/index.jsx" /* webpackChunkName: "component---src-pages-production-index-jsx" */),
  "component---src-pages-quince-index-jsx": () => import("./../../../src/pages/quince/index.jsx" /* webpackChunkName: "component---src-pages-quince-index-jsx" */),
  "component---src-pages-taste-index-jsx": () => import("./../../../src/pages/taste/index.jsx" /* webpackChunkName: "component---src-pages-taste-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */),
  "component---src-pages-where-to-buy-index-jsx": () => import("./../../../src/pages/where-to-buy/index.jsx" /* webpackChunkName: "component---src-pages-where-to-buy-index-jsx" */)
}

