import React from "react"
import i18next from "i18next"
import * as ReactI18next from "react-i18next"

const PageContext = React.createContext({})

export const PageContextProvider = ({ pageContext, children }) => {
  const i18n = i18next
    .createInstance({
      lng: pageContext.lang || "sr",
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: pageContext.i18nResources,
    })
    .use(ReactI18next.initReactI18next)

  i18n.init()

  return (
    <ReactI18next.I18nextProvider i18n={i18n}>
      <PageContext.Provider value={pageContext}>
        {children}
      </PageContext.Provider>
    </ReactI18next.I18nextProvider>
  )
}

export const usePageContext = () => React.useContext(PageContext)
